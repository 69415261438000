<template>
  <div id="app">
    <div style="width: 240px">
      <img alt="Fedeprocapp" class="img" src="./assets/logoFedeprocap.svg" />
      <br />
      <p
        style="
          margin: 0;
          text-align: right;
          color: #999;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
          font-size: 0.8em;
        "
      >
        Cargando...
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  components: {},

  created() {
    this.hasInternetConnection();
    window.addEventListener('online', this.hasInternetConnection);
    window.addEventListener('offline', this.hasInternetConnection);
  },

  mounted() {
    const [token, refresh] = this.getTokens();

    if (token && refresh) {
      // Si no tiene conexión no comprobar el usuario
      if (!this.isOnline) {
        location.replace('/app');
      }

      axios
        .post('/verify', null, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-token': token,
            'X-refresh': refresh,
          },
        })
        .then((result) => {
          if (result.data.success) {
            window.location.href = '/app';
          } else {
            window.location.href = '/auth';
          }
        })
        .catch(() => {
          window.location.href = '/auth';
        });
    } else {
      window.location.href = '/auth';
    }
  },

  data: () => ({
    isOnline: false,
  }),

  methods: {
    hasInternetConnection() {
      this.isOnline = navigator.onLine;
    },

    getTokens() {
      return [localStorage.token, localStorage.refresh];
    },

    saveTokens(token, refresh) {
      localStorage.setItem('token', token);
      localStorage.setItem('refresh', refresh);
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  background-color: #d9d9d9;
  background: linear-gradient(360deg, #dee1e1 10%, #f4f4f4 360%);
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.img {
  width: 100%;
}
</style>
